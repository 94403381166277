const SportFW = (function () {
    let sbtechApi;

    // This old init function is to be deprecated, and replaced by initSportOnFetch
    const initSport = (element, params) => ng_fw.openSport(document.querySelector(element), params)
        .then(afterSportLoads);

    const initSportOnFetch = (element, params) => AsgFW.getFetchData()
        .openSport(document.querySelector(element), params)
        .then((sportApi) => {
            afterSportLoads(sportApi);
        });

    const afterSportLoads = (apiObj) => {
        sbtechApi = apiObj;
    };

    const goToBetSlip = () => sbtechApi.goToBetSlip();

    const getBalance = (realBalance) => (realBalance
        ? AsgFW.getDisplayBalance().DisplayRealBalance
        : AsgFW.getDisplayBalance().DisplayBalance);

    return {
        initSport,
        initSportOnFetch,
        goToBetSlip,
        getBalance,
    };
})();
window.SportFW = SportFW;

/*** EXPORTS FROM exports-loader ***/
export {
  SportFW
};
